import React from "react";
import { MDBAnimation, MDBModal, MDBModalBody } from "mdbreact";
import Lightbox from "react-image-lightbox";
import moment from "moment";
const images = [
  "images/portfolio/page1.jpg",
  "images/portfolio/page2.jpg",
  "images/portfolio/page3.jpg",
];
const imgProductInfo = ["images/assets/ProductInfo.jpg"];
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenProductInfo: false,
      isOpenGetToKnow: false,
      isOpenHandle: false,
      isOpenClip: false,
      timeText: "",
      photoIndex: 0,
      photoIndexProduct: 0,
      isOpenPortfolio: false,
      isFullScreen: false,
    };
  }
  componentDidMount() {
    let timeNow = new Date();
    let timeNowH = moment(timeNow).format("HH");
    let timeNowHInt = parseInt(timeNowH);
    //let timeNowHInt = 16;
    if (timeNowHInt < 12 && timeNowHInt >= 6) {
      this.setState({ timeText: "morning" });
      console.log("morning");
    } else if (timeNowHInt < 18 && timeNowHInt >= 12) {
      this.setState({ timeText: "afternoon" });
      console.log("afternoon");
    } else {
      this.setState({ timeText: "night" });
      console.log("night");
    }
  }
  componentWillUnmount() {}

  toggleGetToKnow = () => {
    this.setState({ isOpenGetToKnow: !this.state.isOpenGetToKnow });
  };
  togglePortfolio = () => {
    this.setState({ isOpenPortfolio: !this.state.isOpenPortfolio });
  };
  toggleProductInfo = () => {
    this.setState({ isOpenProductInfo: !this.state.isOpenProductInfo });
  };
  toggleHandle = () => {
    this.setState({ isOpenHandle: !this.state.isOpenHandle });
  };
  toggleClip = () => {
    this.setState({ isOpenClip: !this.state.isOpenClip });
  };
  toggleFullScreen = () => {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullscreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  };
  render() {
    return (
      <>
        <div className="checkLandscape">
          <p>กรุณาเข้าชมเว็บไซต์ในแนวนอน</p>
          <p>Open website in landscape</p>
        </div>
        <div className="mainContainVirtual">
          <div className="screenStage">
            {this.state.timeText === "morning" && (
              <img
                src="images/backgrounds/stage_morning.jpg"
                alt="stage morning"
                className="imgScreenStage"
              />
            )}
            {this.state.timeText === "afternoon" && (
              <img
                src="images/backgrounds/stage_evening.jpg"
                alt="stage morning"
                className="imgScreenStage"
              />
            )}
            {this.state.timeText === "night" && (
              <img
                src="images/backgrounds/stage_night.jpg"
                alt="stage morning"
                className="imgScreenStage"
              />
            )}
            <MDBAnimation className="plant_left" type="fadeInLeft" delay="1.5s">
              <img
                src="images/assets/plant_left.png"
                className="img-fluid"
                alt="plant"
              />
            </MDBAnimation>
            <MDBAnimation
              className="plant_corner_top_right"
              type="fadeInRight"
              delay="1.5s"
            >
              <img
                src="images/assets/plant_corner_top_right.png"
                className="img-fluid"
                alt="plant"
              />
            </MDBAnimation>
            <MDBAnimation
              className="plant_corner_bottom_right"
              type="fadeInUp"
              delay="1.5s"
            >
              <img
                src="images/assets/plant_corner_bottom_right.png"
                className="img-fluid"
                alt="plant"
              />
            </MDBAnimation>
            <audio controls autoPlay loop className="audioTag">
              <source src="sound.mp3" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
            <MDBAnimation type="flash" duration="7s" infinite={true}>
              <img
                src={
                  this.state.timeText === "morning"
                    ? "images/button/Gettokhow.png"
                    : "images/button/GettokhowWhite.png"
                }
                className="btnGetToKnow"
                alt="button"
                onClick={this.toggleGetToKnow}
              />
            </MDBAnimation>
            <MDBAnimation type="flash" duration="6.8s" infinite={true}>
              <img
                src="images/button/Portfolio.png"
                className="btnPortfolio"
                alt="button"
                onClick={this.togglePortfolio}
              />
            </MDBAnimation>
            <MDBAnimation type="flash" duration="6.5s" infinite={true}>
              <img
                src={
                  this.state.timeText === "morning"
                    ? "images/button/ProductInfomation.png"
                    : "images/button/ProductInfomationWhite.png"
                }
                className="btnProductInfomation"
                alt="button"
                onClick={this.toggleProductInfo}
              />
            </MDBAnimation>
            <MDBAnimation type="flash" duration="6.2s" infinite={true}>
              <img
                src="images/button/HowToHandling.png"
                className="btnHowToHandling"
                alt="button"
                onClick={this.toggleHandle}
              />
            </MDBAnimation>
            <MDBAnimation type="flash" duration="6.4s" infinite={true}>
              <img
                src="images/button/VDOClip.png"
                className="btnVDOClip"
                alt="button"
                onClick={this.toggleClip}
              />
            </MDBAnimation>
            <MDBAnimation type="flash" duration="6.85s" infinite={true}>
              <a
                href="https://m.me/103654418172953"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    this.state.timeText === "morning"
                      ? "images/button/Chat.png"
                      : "images/button/ChatWhite.png"
                  }
                  className="btnChat"
                  alt="button"
                />
              </a>
            </MDBAnimation>
            <a
              href="https://www.fresenius-kabi.com/th/company"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="images/button/contact.png"
                className="btnContact"
                alt="button"
              />
            </a>
          </div>
          {this.state.isOpenProductInfo && (
            <Lightbox
              mainSrc={imgProductInfo[this.state.photoIndexProduct]}
              onCloseRequest={() => this.setState({ isOpenProductInfo: false })}
            />
          )}
          {this.state.isOpenPortfolio && (
            <Lightbox
              mainSrc={images[this.state.photoIndex]}
              nextSrc={images[(this.state.photoIndex + 1) % images.length]}
              prevSrc={
                images[
                  (this.state.photoIndex + images.length - 1) % images.length
                ]
              }
              onCloseRequest={() => this.setState({ isOpenPortfolio: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (this.state.photoIndex + 1) % images.length,
                })
              }
            />
          )}
          <MDBModal
            isOpen={this.state.isOpenGetToKnow}
            toggle={this.toggleGetToKnow}
            size="lg"
          >
            <MDBModalBody>
              <div className="embed-responsive embed-responsive-16by9">
                {this.state.isOpenGetToKnow && (
                  <iframe
                    title="embedsPage"
                    className="embed-responsive-item"
                    src="https://player.vimeo.com/video/462569822"
                    allowfullscreen
                  ></iframe>
                )}
              </div>
            </MDBModalBody>
          </MDBModal>
          <MDBModal
            isOpen={this.state.isOpenHandle}
            toggle={this.toggleHandle}
            size="lg"
          >
            <MDBModalBody>
              <div className="embed-responsive embed-responsive-16by9">
                {this.state.isOpenHandle && (
                  <iframe
                    title="embedsPage"
                    className="embed-responsive-item"
                    src="https://player.vimeo.com/video/462571206"
                    allowfullscreen
                  ></iframe>
                )}
              </div>
            </MDBModalBody>
          </MDBModal>
          <MDBModal
            isOpen={this.state.isOpenClip}
            toggle={this.toggleClip}
            size="lg"
          >
            <MDBModalBody>
              <div className="embed-responsive embed-responsive-16by9">
                {this.state.isOpenClip && (
                  <iframe
                    title="embedsPage"
                    className="embed-responsive-item"
                    src="https://player.vimeo.com/video/462571348"
                    allowfullscreen
                  ></iframe>
                )}
              </div>
            </MDBModalBody>
          </MDBModal>
        </div>
      </>
    );
  }
}

export default App;
